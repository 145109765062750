import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

const Post = styled.li`
  position: relative;
  border: none;
  border-radius: 0px;
  margin: 0 0 0 0;
  width: 100%;
  max-width: 100%;
  transition: background-color 0.2s;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 50%')};
    max-width: 50%;
    margin: 0 0 0 0;
  }
  &:hover {
    background-color: ${props => props.theme.colors.background};
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    max-width: 100%;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '40%' : '60%')};
      }
    }
  }
`

const Meta = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 15px;
  h2,
  p {
    font-size: ${props => props.theme.fontSizes[5]}px;
    @media screen and (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[4]}px;
    }
    @media screen and (max-width: ${props =>
        props.theme.responsive.mediumMax}) {
      font-size: ${props => props.theme.fontSizes[3]}px;
    }
    @media screen and (max-width: ${props => props.theme.responsive.smallMax}) {
      font-size: ${props => props.theme.fontSizes[2]}px;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex-flow: row nowrap;
    padding: 20px;
  }
`

const Title = styled.h2`
  font-weight: 400;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: 1 1 80%;
    padding-right: 20px;
  }
`

const Date = styled.p`
  color: gray;
  flex: 1 1 100%;
  text-align: left;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: 1 1 20%;
    text-align: right;
    padding-left: 20px;
  }
`

const Card = ({ slug, heroImage, title, publishDate, body, ...props }) => {
  return (
    <>
      {heroImage && body && (
        <Post featured={props.featured}>
          <Link to={`${props.basePath}/${slug}/`}>
            <Img
              fluid={heroImage.fluid}
              backgroundColor={'#eeeeee'}
              objectFit="cover"
              objectPosition="50% 50%"
            />
            <Meta>
              <Title>{title}</Title>
              <Date>{publishDate}</Date>
            </Meta>
          </Link>
        </Post>
      )}
    </>
  )
}

export default Card
