import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import ContainerHero from '../components/ContainerHero'
import ContainerContent from '../components/ContainerContent'
import { startCase } from 'lodash'
import PageTitle from '../components/PageTitle'
import HeroLeftStandard from '../components/HeroLeftStandard'
import Newsletter from '../components/NewsletterPopup.js'

const Posts = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const { basePath } = pageContext
  let ogImage

  try {
    ogImage = posts[0].node.heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <Newsletter color="news" />
      <SEO title={startCase(basePath)} image={ogImage} />
      <Container>
        <ContainerHero>
          <HeroLeftStandard>
            <PageTitle>
              News
              <br />
              Übersicht
            </PageTitle>
          </HeroLeftStandard>
        </ContainerHero>
        <ContainerContent>
          <CardList>
            {posts.map(({ node: post }) => (
              <Card key={post.id} {...post} basePath={basePath} />
            ))}
          </CardList>
          <Pagination context={pageContext} />
        </ContainerContent>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MM/YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          body{
            json
          }
        }
      }
    }
  }
`

export default Posts
