import React from 'react'
import styled from '@emotion/styled'

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0;
  opacity: 1 !important;
  a {
    opacity: 1 !important;
    &:hover img {
      transform: scale(1.1);
      filter: blur(5px);
    }
    .gatsby-image-wrapper {
      overflow: hidden;
    }
  }
  &::after {
    content: '';
    flex: 0 0 32%;
  }
  li {
    opacity: 1 !important;
    overflow: hidden;
    border-top: 2px solid #000;
  }
  li:nth-of-type(odd) {
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      border-right: 2px solid #000;
    }
  }
`

const CardList = props => {
  return <List>{props.children}</List>
}

export default CardList
