import React from 'react'
import { navigate, Link } from 'gatsby'
import styled from '@emotion/styled'
import SelectIcon from '../icons/SelectIcon'

const Wrapper = styled.div`
  border-top: 2px solid #000;
  width: 100%;
  margin: 0;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
  }
`

const ContainerButtons = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0;
  padding: 0;
  flex: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: stretch;
`

const ContainerButton = styled.div`
  margin: 0;
  padding: 0;
  flex: 0 1 190px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-left: 2px solid #000;
`

const Button = styled(Link)`
  opacity: 1 !important;
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  padding: 15px;
  width: 100%;
  border-radius: 0px;
  margin: 0 0 0 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: 0.3s all;
  &:hover {
    background: ${props => props.theme.colors.purple} !important;
  }
`

const Numbers = styled.div`
  flex: 0 0 120px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${props => props.theme.colors.text};
  padding: 5px 15px;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    padding: 5px 20px;
  }
  background: white;
  position: relative;
  transition: 0.3s all;
  svg {
    fill: ${props => props.theme.colors.text};
    margin: 0 0 0 0.25rem;
    transition: 0.3s all;
  }
  &:hover {
    background: ${props => props.theme.colors.background};
  }
`

const Select = styled.select`
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  color: transparent;
  option {
    color: black;
  }
`

const Pagination = props => {
  function changePage(e) {
    navigate(
      e.target.value
        ? `${props.context.paginationPath}/${e.target.value}/`
        : `${props.context.paginationPath}/`
    )
  }

  return (
    <>
      {props.context.numberOfPages > 1 && (
        <Wrapper>
          <Numbers>
            {props.context.humanPageNumber}{' '}
            <Select
              value={
                props.context.humanPageNumber === 1
                  ? ``
                  : props.context.humanPageNumber
              }
              onChange={changePage}
            >
              {Array.from({ length: props.context.numberOfPages }, (_, i) => (
                <option value={`${i === 0 ? `` : i + 1}`} key={`page${i + 1}`}>
                  {i + 1}
                </option>
              ))}
            </Select>
            / {props.context.numberOfPages} <SelectIcon />
          </Numbers>
          <ContainerButtons>
            {props.context.previousPagePath && (
              <ContainerButton>
                <Button to={`${props.context.previousPagePath}`}>
                  <span>&larr;</span> Zurück
                </Button>
              </ContainerButton>
            )}

            {props.context.nextPagePath && (
              <ContainerButton>
                <Button
                  style={{ order: 3 }}
                  to={`${props.context.nextPagePath}`}
                >
                  Weiter <span>&rarr;</span>
                </Button>
              </ContainerButton>
            )}
          </ContainerButtons>
        </Wrapper>
      )}
    </>
  )
}

export default Pagination
